<template lang="pug">
    v-card(:height="windowHeight-6" :max-height="windowHeight" outlined elevation='0').caseDialog
        v-card-title.d-flex.justify-center {{$t('BULK_EDIT.TITLE')}}
        v-card(:height="change_stage? processHeight:windowHeight-200" :max-height="change_stage? processHeight:windowHeight-200"  outlined elevation='0'  class='scrollbar').caseDialog
            v-card-text
                v-form(v-model='addCaseValid' ref='form')  
                    v-card-text(height="100" max-height="100" class='scrollbar')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.NAME')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.subject.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='subject', @change="visibleChange('subject')")
                            v-text-field(ref='subject_val' v-if='editCaseData.subject.change == 1' v-model='editCaseData.subject.value' :label=`$t('ADD_CASE.NAME')` prepend-icon='mdi-file-document' :rules='formRules.nameRule' required='required' large='large' validate-on-blur)
                        label.ellipsis.select-label-size(style='width: 300px;' v-if='!autoNumberCheck') {{$t('CASE_DETAIL.CASE_NUMBER')}}
                        div.bulk-edit-square(outline v-if='!autoNumberCheck')
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.case_number.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='case_number', @change="visibleChange('case_number')")
                            v-text-field(ref='case_number_val' v-if='editCaseData.case_number.change == 1' v-model='editCaseData.case_number.value' :label=`$t('CASE_DETAIL.CASE_NUMBER')` prepend-icon='mdi-file-document' :rules='formRules.contentLenRule50Require' required='required' large='large' validate-on-blur)
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.EXPECTED_CLOSE_DATE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.due_date.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='due_date', @change="visibleChange('due_date')")
                            v-menu.no-padding(v-if='editCaseData.due_date.change == 1' ref="menu" v-model="menu[0]" :z-index='10000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.no-border.height-48(v-model='editCaseData.due_date.value'  v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' readonly hide-details="auto" :label= `$t('ADD_CASE.EXPECTED_CLOSE_DATE')` ref='due_date_val',:rules='formRules.dateRule')
                                v-date-picker( v-model='editCaseData.due_date.value' @input='menu[0] = false' no-title scrollable)
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.CONTACT')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.primary_contact.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='primary_contact', @change="visibleChange('primary_contact')")
                            v-combobox.no-border.height-48( v-if="!isNewContact && editCaseData.primary_contact.change ==1 " v-model="editCaseData.primary_contact.value" :items="contactList" @blur='contactList = []' ref='primary_contact_val' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_CASE.CONTACT')`  :loading='contact_loading' :rules='formRules.contactRules')
                                template(v-slot:prepend-item)
                                    v-list-item
                                        v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                                            v-icon.mr-1(size="14") icon-add
                                            span {{$t('ADD_CASE.NEW_CONTACT')}}
                                template(v-slot:item='data')
                                    v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                        v-icon(v-if="data.item.image == null") mdi-account
                                        img(v-else :src='data.item.image')
                                    .t-black {{ data.item.full_name }} 
                                    .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                template(v-slot:selection='data')
                                    .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                            v-row(no-gutters="no-gutters")
                                v-col(cols="6")
                                    v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('ADD_CONTACT.LAST_NAME')` large="large",:rules='formRules.contentLenRule50Require')
                                v-col(cols="6")
                                    v-text-field.ml-3(v-if="isNewContact " v-model="newContact.first_name" :label=`$t('ADD_CONTACT.FIRST_NAME')` large="large",:rules='formRules.contentLenRule50')  
                                v-col(cols="6")
                                    v-btn.w-100.justify-start.px-0(v-if="isNewContact" text color="blue" plain border-0 @click="isNewContact = false")
                                        v-icon(size="20") mdi-keyboard-return 
                                        span.ml-4 {{$t('ADD_CASE.OLD_CONTACT')}}
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.ORG')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.case_org.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='case_org', @change="visibleChange('case_org')")
                            v-combobox.no-border.height-48(v-if='editCaseData.case_org.change == 1'  v-model='editCaseData.case_org.value' :items='organizationList' item-text='name' item-value="uqNumber", ref='case_org_val', :loading="isLoading" :search-input.sync="search" prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ADD_CASE.ORG')` :rules='formRules.companyRule')
                                template(v-slot:append-outer)
                                    div(v-if="isNew")
                                        v-icon(color="red", size="25") mdi-new-box
                                template(v-slot:item="data")
                                    div.ellipsis(v-if="data.item.id == 0")
                                        span {{ data.item.name }}
                                        v-icon(color="red", size="25") mdi-new-box
                                    div.ellipsis(v-else)
                                        span {{ data.item.name }}
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.INCOMING_CONTACT')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.incoming_fullname.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='incoming_fullname', @change="visibleChange('incoming_fullname')")
                            v-text-field.no-border.height-48(v-if='editCaseData.incoming_fullname.change == 1'  v-model='editCaseData.incoming_fullname.value' :label=`$t('CASE_DETAIL.INCOMING_CONTACT')`,ref='incoming_fullname_val', ,:rules='formRules.fieldRules' prepend-icon='mdi-account' large='large')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.PHONE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.incoming_phone.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='incoming_phone', @change="visibleChange('incoming_phone')")
                            //- v-text-field.no-border.height-48(v-if='editCaseData.incoming_phone.change == 1'  v-model='editCaseData.incoming_phone.value' :label=`$t('ADD_CASE.PHONE')` ,:rules='formRules.fieldRules',ref='incoming_phone_val', prepend-icon='mdi-cellphone' large='large')
                            v-row(no-gutters="no-gutters" v-if='editCaseData.incoming_phone.change == 1')
                                v-col(cols="2")
                                    v-icon(size='20') mdi-cellphone
                                v-col
                                    vue-tel-input-vuetify.no-border(
                                    v-model="editCaseData.incoming_phone.value",
                                    solo,
                                    :label=`$t('ADD_CONTACT.MOBILE_PHONE')`,
                                    :selectLabel=`$t('ADD_CONTACT.SELECT_LABEL')`,
                                    placeholder='',
                                    autocomplete='off',
                                    :defaultCountry='getLocation()',
                                    disabledFetchingCountry=true,
                                    mode='international',
                                    @validate="onPhoneCheck($event)",
                                    @input="onPhoneInput",
                                    :rules="formRules.phoneRules",
                                    ,ref='mobile_phone_val',
                                    )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.EMAIL')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.incoming_email.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='incoming_email', @change="visibleChange('incoming_email')")
                            v-text-field.no-border.height-48(v-if='editCaseData.incoming_email.change == 1'  v-model='editCaseData.incoming_email.value' :label=`$t('ADD_CASE.EMAIL')`, :rules='formRules.emailRules',ref='incoming_email_val', prepend-icon='mdi-email' large='large')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('GENERAL.CHANGE_OWNER_2')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.owner.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='owner', @change="visibleChange('owner')")
                            v-autocomplete.no-border.height-48(v-if='editCaseData.owner.change == 1'  v-model='editCaseData.owner.value' :items='ownerList' item-text='fullName' item-value='id' ,ref='owner_val', :label=`$t('GENERAL.CHANGE_OWNER_2')` return-object rounded prepend-icon='mdi mdi-account',:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('LOSEREASON.CLOSE_REASON')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.closed_reason.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='closed_reason', @change="visibleChange('closed_reason')")
                            v-select.no-border.height-48(v-if='editCaseData.closed_reason.change == 1'  v-model='editCaseData.closed_reason.value' :label=`$t('LOSEREASON.CLOSE_REASON')`,  :items='loseReasonList' ,ref='closed_reason_val', prepend-icon='mdi-comment-check-outline' item-text='name' item-value='key' :menu-props='{closeOnClick:true}' :rules='formRules.fieldRules')
                            v-menu(v-if='editCaseData.closed_reason.value && editCaseData.closed_reason.change == 1' ref="menu" v-model="menu[1]" :z-index='10000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.no-border.height-48(v-model='editCaseData.closed_date.value'  :rules='formRules.closed_dateRule'  v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' readonly  hide-details="auto" :label= `$t('LOSEREASON.CLOSE_DATE')` )
                                v-date-picker(v-model='editCaseData.closed_date.value' @input='menu[1] = false' no-title scrollable)
                        label.ellipsis.select-label-size(style='width: 300px;' v-if='editCaseData.closed_reason.change != 1' ) {{$t('CASE_DETAIL.CASE_STATUS')}}
                        div.bulk-edit-square(outline v-if='editCaseData.closed_reason.change != 1' )
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.status.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='status', @change="visibleChange('status')")
                            v-select.no-border.height-48(v-if='editCaseData.status.change == 1'  v-model='editCaseData.status.value' :label=`$t('CASE_DETAIL.CASE_STATUS')`,  :items='caseStatusList',ref='status_val', prepend-icon='mdi-newspaper' item-text='name' item-value='key' :menu-props='{closeOnClick:true}' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.PRIORITY')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.priority.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='priority', @change="visibleChange('priority')")
                            v-select.no-border.height-48(v-if='editCaseData.priority.change == 1'  v-model='editCaseData.priority.value' :label=`$t('CASE_DETAIL.PRIORITY')`,  :items='casePriorityList',ref='priority_val', prepend-icon='mdi-arrow-up-thick' item-text='name' item-value='key' :menu-props='{closeOnClick:true}' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.SOURCR')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.source.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='source', @change="visibleChange('source')")
                            v-select.no-border.height-48(v-if='editCaseData.source.change == 1'  v-model='editCaseData.source.value'  :items='caseSourceList' prepend-icon='fa-solid fa-arrow-right',ref='source_val', item-text='name' item-value='key' :label= `$t('ADD_CASE.SOURCR')` ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.GROUP')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.group.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='group', @change="visibleChange('group')")
                            v-select.no-border.height-48(v-if='editCaseData.group.change == 1'  v-model='editCaseData.group.value'  :items='caseGroupList' prepend-icon='fa-solid fa-clipboard',ref='group_val', item-text='group_name' item-value='id' :label= `$t('ADD_CASE.GROUP')` ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.TYPE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.type.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='type', @change="visibleChange('type')")
                            v-select.no-border.height-48(v-if='editCaseData.type.change == 1'  v-model='editCaseData.type.value'  :items='caseTypeList' prepend-icon='fa-solid fa-stamp' item-text='type_name',ref='type_val', item-value='id' :label= `$t('ADD_CASE.TYPE')` ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CASE.PARENT_CASE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.parent_case.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='parent_case', @change="visibleChange('parent_case')")
                            v-combobox.no-border.height-48(v-if='editCaseData.parent_case.change == 1'  v-model='editCaseData.parent_case.value' :items='caseParentCaseList' @blur='caseParentCaseList=[]' item-text='subject',ref='parent_case_val', item-value="id" :loading='parent_case_list_loading' :search-input.sync="search_parent_case" prepend-icon='mdi-wrench' hide-details="auto" :label=`$t('ADD_CASE.PARENT_CASE')` ,:rules='formRules.fieldRules')
                                template(v-slot:item="data")
                                    div.ellipsis
                                        span {{ data.item.subject }}
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.RELATED_CAMPAIGN')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.primary_campaign_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='primary_campaign_id', @change="visibleChange('primary_campaign_id')")
                            v-autocomplete.no-border(v-if='editCaseData.primary_campaign_id.change == 1'  v-model='editCaseData.primary_campaign_id.value'  :items='campaignList' item-text='name' item-value='id',ref='primary_campaign_id_val', :menu-props='{closeOnClick:true}' :label=`$t('CASE_DETAIL.RELATED_CAMPAIGN')` prepend-icon='mdi-tag' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.DESCRIPTION')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.description.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='description', @change="visibleChange('description')")
                            v-textarea.no-border.textarea-style(v-if='editCaseData.description.change == 1'  v-model='editCaseData.description.value'  dense='dense' rows='3',ref='description_val', :label=`$t('CASE_DETAIL.DESCRIPTION')` prepend-icon='mdi-tag' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.DESCRIPTION_2')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editCaseData.resolution.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='resolution', @change="visibleChange('resolution')")
                            v-textarea.no-border.textarea-style(v-if='editCaseData.resolution.change == 1'  v-model='editCaseData.resolution.value'  dense='dense' rows='3',ref='resolution_val', :label=`$t('CASE_DETAIL.DESCRIPTION_2')` prepend-icon='mdi-tag' ,:rules='formRules.fieldRules')
                        v-window
                            customField(v-if='!isLoading && isProcess' ref='CFRequired' DataClass = 'Case'  :StageTypeId='passId' :stageList='stageList')
        v-card-text
            v-checkbox.py-0.ml-auto(v-model='change_stage' dense='dense' :hide-details='true' :label= `$t('BULK_EDIT.CHANGE_STAGE')`)
            v-combobox.no-border.my-0.py-0.mt-6(v-if='change_stage' :label= `$t('ADD_CASE.SELECT')` v-model='editCaseData.stage_type.value' :items='stageList' item-text='stage_type_name' @change='selectStageType()' prepend-icon='mdi-sync' hide-details="auto" flat required='required' validate-on-blur :rules='formRules.stageTypeRule')
            div(style='height: 40px;' v-if='change_stage')
                .my-2.d-flex.justify-center.align-center.w-100.h-100(v-if='!isProcess && editCaseData.stage_type.value != ""')
                    v-progress-circular(indeterminate size='20')
                v-stepper.process-box.my-2(v-if='isProcess && process.length > 0' v-model='currentStage')
                    v-stepper-header.process-bar
                        template(v-for='item in process')
                            v-stepper-step(:key='`${item.sequence_number}`' :complete='currentStage>item.sequence_number' :step='item.sequence_number' :class='`customcolor`' :style='getcolor(item.stage_color)' edit-icon='icon-check' editable)
                                span {{ item.stage_name }}
                        v-btn(v-if='lenProcess > 3 && process[0].sequence_number != 1' absolute style="marginTop: 4px; zIndex:1000;" class="mx-1" fab dark x-small color="white" @click='preStage()')
                            v-icon(color="primary") icon-arrow-left
                        v-btn(v-if='lenProcess > 3 && process[2].sequence_number != lenProcess' absolute style="marginLeft: 93.5%; marginTop: 4px; zIndex:1000;"  fab dark x-small color="white" @click='nextStage()')
                            v-icon(color="primary") icon-arrow-right
                v-stepper.process-box.my-2(v-if='isProcess && process.length == 0 || editCaseData.stage_type.value == ""')
                    v-stepper-header.process-bar.lightGrey.rounded-pill.d-flex.justify-center.align-center
                        span(style='color: rgba(0, 0, 0, 0.38);') {{$t('ADD_CASE.PROCESS')}}
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='primary' depressed :disabled="editBtn_loading || contact_loading || isLoading || !isProcess")
                        v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' persistent)
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>

<script>
    import Vue from 'vue';
    import ContactDataService from "@/services/ContactDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import StageDataService from "@/services/StageDataService";
    import CaseDataService from "@/services/CaseDataService";
    import CampaignDataService from "@/services/CampaignDataService";
    import errorDialog from "@/components/Dialog/errorDialog";
    import caseSourceList from "@/array/the_caseSourceList";
    import i18n from '/common/plugins/vue-i18n.js';
    import casePriorityList from "@/array/the_casePriorityList";
    import closeCaseReasonList from "@/array/the_closeCaseReasonList";
    import customField from "@/components/Dialog/BulkEdit/customFieldBulkEdit";
    import messageDialog from '@/components/Dialog/messageDialog';
    import bulkEditList from "@/array/the_bulkEditList";
    import caseStatusList from "@/array/the_caseStatusList";

    export default Vue.extend({
        props: {
            editItems: {
                type: Array,
                required: true,
            },
            stageTypeId: {
                type: Object,
                required: true,
            },
            autoNumberCheck: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            customField,
            errorDialog,
            messageDialog,
        },
        data() {
            return {
                isLoading: false,
                addCaseValid: true,
                formRules: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_TITLE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    closed_dateRule: [(v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')],
                    dateRule: [(v) => !!v || i18n.t('RULE.RULE_DUE_DATE'),],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case_Contact')],
                    phoneRules: [(v) => !!v || i18n.t('RULE.RULE_R'),
                                (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')
                    ],
                    emailRules: [
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                        (v) => !!v || i18n.t('RULE.RULE_R')
                    ],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                        (v) => !!v || i18n.t('RULE.RULE_R')
                    ],
                    contentLenRule50Require: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    contentLenRule50: [
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                },
                allProcess: [],
                process: [],
                contactList: [],
                organizationList: [],
                stageList: [],
                caseGroupList: [],
                caseTypeList: [],
                caseCategoryList: [],
                caseParentCaseList:[],
                comboText: '',
                search: null,
                menu: [false,false],
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: ''
                },
                errorDialog: false,
                errorMessage: '',
                isProcess: false,
                isContact: true,
                currentStage: 1,
                lenProcess: '',
                editBtn_loading: false,
                caseSourceList: caseSourceList,
                stage_type_data: {},
                contactSearch: null,
                contact_loading: false,
                user: JSON.parse(window.localStorage.getItem('user')),
                search_parent_case : null,
                parent_case_list_loading: false,
                comboInput_parent_case : null,
                editCaseData:{
                    subject: {change:0,value:''},
                    case_number: {change:0,value:''},
                    primary_contact: {change:0,value:''},
                    incoming_phone:{change:0,value:''},
                    incoming_email:{change:0,value:''},
                    case_org: {change:0,value:''},
                    source: {change:0,value:''},
                    group: {change:0,value:''},
                    type: {change:0,value:''},
                    category: {change:0,value:''},
                    due_date: {change:0,value:''},
                    current_stage: 0,
                    stage_type: {change:0,value:this.stageTypeId},
                    incoming_fullname:{change:0,value:''},
                    status:{change:0,value:''},
                    priority:{change:0,value:''},
                    description:{change:0,value:''},
                    resolution:{change:0,value:''},
                    closed_reason:{change:0,value:''},
                    closed_date: {change:0,value:''},
                    owner: {change:0,value:''},
                    custom_field:{change:0,value:''},
                    parent_case:{change:0,value:''},
                    primary_campaign_id:{change:0,value:''},
                },
                change_stage:false,
                caseStatusList: caseStatusList,
                casePriorityList: casePriorityList,
                loseReasonList: closeCaseReasonList,
                reason:'',
                ownerList: [],
                messageDialog: false,
                message: '',
                customFieldData:[],
                windowHeight: window.innerHeight,
                processHeight: window.innerHeight-300,
                selectToChange: bulkEditList,
                selectToChangeNoDel: bulkEditList.filter(el=>el.key!=2),
                bulkEditData:{},
                campaignList:[],
                cfValid:true,
                isNumVaild: true,
                vue_tel: {
                    isValid: true,
                },
                passId:this.stageTypeId.id,
                isEditLoading:false,
            }
        },
        
        async created(){
            this.caseStatusList = this.caseStatusList.filter(el=>el.key!="CLOSED")
            await CampaignDataService.selectlist().then((response)=>{
                this.campaignList = response.data;
            })
            OrganizationDataService.getchangeowner('cases')
            .then((respose)=>{
                this.ownerList = respose.data;
                this.ownerList.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name
                });
            });
            CaseDataService.getcasegrouplist()
            .then(response => {
                this.caseGroupList = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });

            CaseDataService.getcasetypelist()
            .then(response => {
                this.caseTypeList = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });

            CaseDataService.getcasecategorylist()
            .then(response => {
                this.caseCategoryList = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });
            
            await StageDataService.stageList("case")
            .then(response => {
                this.stageList = response.data;
                this.currentStage = 1;
            })
            .finally(() => (this.isLoading = false));

            if (this.editCaseData.stage_type.value != undefined ) {
                if(this.editCaseData.stage_type.value.id=="all"){
                    this.editCaseData.stage_type.value = this.stageList[0]
                }
                this.allProcess = this.stageList.filter(s => s.id == this.editCaseData.stage_type.value.id)[0].stagedef_set;
                if (this.editCaseData.current_stage == undefined) {
                    if (this.editCaseData.stage_type.value.stagedef_set == undefined) {
                        this.currentStage = 1;
                    } else {
                        this.currentStage = this.editCaseData.stage_type.value.stagedef_set.sequence_number;
                    }
                } else if (this.editCaseData.current_stage == 0) {
                    this.currentStage = 1;
                } else {
                    this.currentStage = this.allProcess.find(p => { return p.id == this.editCaseData.current_stage}).sequence_number;
                }
                this.selectStageType();
            } else {
                let default_process = null;
                try {
                    default_process = this.user.default_value.default_process;
                } 
                catch (error) {
                    default_process = null;
                }

                if(default_process == null || default_process.case == null){
                    this.stageList.forEach(e => {
                        e.is_admin_default == true ? this.editCaseData.stage_type = e : '';
                    })
                }
                else{
                    this.stageList.forEach(e => {
                        e.id == default_process.case ? this.editCaseData.stage_type = e : '';
                    })
                }
                this.currentStage = 1;
                this.selectStageType();
            }
        },
        methods: {
            visibleChange(blurVal){
                this.$refs[blurVal].blur()
                let focuVal = blurVal+'_val'
                setTimeout(()=>{
                    try{
                        this.$refs[focuVal].focus();
                        this.$refs[focuVal].isMenuActive = true;
                    }catch{
                    // pass;
                    }
                },100)
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            async onConfirm() {
                this.formRules.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))});
                this.formRules.dateRule.push((v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE'));
                if (await this.validateForm()) {
                    let primary_contact = this.editCaseData.primary_contact.value;
                    if(!this.isNewContact && typeof(primary_contact) === 'string' && primary_contact){
                        this.showErrorDialog(i18n.t('ADD_CASE.ERROR_2'));
                        return;
                    }
                    let parent_case = this.editCaseData.parent_case.value;
                    if(typeof(parent_case) === 'string' && parent_case) {
                        if(this.comboInput_parent_case != this.editCaseData.parent_case.value.subject) {
                            this.showErrorDialog(i18n.t('ADD_CASE.ERROR_4'));
                            this.editBtn_loading = false;
                            return;
                        }
                    }
                    this.editBtn_loading = true;
                    let bulkEditCaseData = {};
                    this.getResponsedata();
                    if(this.cfValid == false){
                        this.cfValid = true;
                        return;
                    }
                    for(let i in this.editCaseData){
                        if(this.editCaseData[i]['change']==1){
                            bulkEditCaseData[i] = this.editCaseData[i]['value'];
                            if(i == 'closed_reason'){
                                bulkEditCaseData['closed_date'] = this.editCaseData['closed_date']['value'];
                            }
                            if(i == 'owner'){
                                bulkEditCaseData[i] = this.editCaseData[i]['value']['id'];
                            }
                            if(i == 'parent_case'){
                                bulkEditCaseData[i] = this.editCaseData[i]['value']['id'];
                            }
                            if(i == 'case_org'){
                                if(typeof(this.editCaseData.case_org.value) == "string" && this.editCaseData.case_org.value != ''){
                                    bulkEditCaseData[i] = {id: "-1", name: this.comboText, company_number: "0"};
                                    }
                            }
                            if(i == 'primary_contact'){
                                if(this.editCaseData.primary_contact.value== '' && this.newContact.last_name !=''){
                                    bulkEditCaseData[i] = {
                                            id: "0",
                                            last_name: this.newContact.last_name,
                                            first_name: this.newContact.first_name != null ? this.newContact.first_name : ""
                                        };
                                    }
                            }
                            if(i == 'priority'){
                                bulkEditCaseData[i] = this.getPriority(this.editCaseData[i]['value']);
                            }
                        }
                        if(this.editCaseData[i]['change']==2){
                            bulkEditCaseData[i] = null
                        }
                    }
                    if(this.change_stage){
                        bulkEditCaseData['stage_type'] = this.editCaseData.stage_type.value.id
                        bulkEditCaseData['current_stage'] = this.allProcess[this.currentStage - 1].id
                    }

                    let editItemName = '';
                    let editItemNameArray = [];
                    let closeCase = '';
                    for(let i in this.editItems){
                        editItemName = i > 0 ? editItemName +','+ this.editItems[i].subject : editItemName + this.editItems[i].subject 
                        editItemNameArray.push(this.editItems[i].id)
                        if(this.editItems[i].closed_reason != null){
                            closeCase = closeCase.length > 0 ? closeCase +','+ this.editItems[i].subject : closeCase + this.editItems[i].subject
                        }
                    }
                    this.bulkEditData = {
                        list:editItemNameArray,
                        data:bulkEditCaseData,
                        cf:this.customFieldData.length > 0 ? this.customFieldData :null
                    };
                    if(this.customFieldData.length === 0 && Object.keys(this.bulkEditData.data).length === 0){
                        this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
                        return;
                    }
                    if(closeCase.length>0){
                        if(this.editCaseData['closed_reason'].change == 2){
                            this.showMessageDialog(this.editItems.length);
                        }else{
                            this.showErrorDialog(`${i18n.t('BULK_EDIT.ERROR_CASE')}<br>${closeCase}`);
                        }
                    }else{
                        this.showMessageDialog(this.editItems.length);
                    }
                }
            },
            onCancel() {
                this.$emit('emitBulkEditDialog', false);
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                if(Object.keys(this.bulkEditData.data).length != 0 || this.customFieldData.length != 0)
                {
                    this.message = `${i18n.t('BULK_EDIT.MESSAGE_DATA')}${message}${i18n.t('BULK_EDIT.MESSAGE_DATA_2')}`;
                }else{
                    this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
                    this.messageDialog = false;
                    return;
                }
            },
            async onEmitMessageDialog(val){
                if(val){
                    if(!this.isEditLoading){
                        this.isEditLoading = true;
                        if(Object.keys(this.bulkEditData.data).length != 0 || this.customFieldData.length != 0){
                            await(this.$emit('emitBulkEditDialog','editing',this.bulkEditData));
                        }
                    }
                }
                this.editBtn_loading = false;
                this.messageDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
                this.editBtn_loading = false;
            },
            async selectStageType() {
                try {
                    if (this.editCaseData.stage_type.value.stagedef_set.length != undefined) {
                        this.allProcess = this.editCaseData.stage_type.value.stagedef_set;
                    }
                    this.allProcess.sort((a, b) => {
                        return a.sequence_number - b.sequence_number;
                    });
                    this.isProcess = true;
                    this.lenProcess = this.allProcess.length;
                    await this.setProcess();
                    await (this.currentStage = this.currentStage - 1);
                } catch {
                    this.isProcess = false;
                    return;
                }
            },
            setProcess() {
                if (this.lenProcess > 3) {
                    if (this.currentStage <  3) {
                        this.process = this.allProcess.slice(0, 3);
                    } else if (this.currentStage > this.lenProcess - 2) {
                        this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                    } else {
                        this.process = this.allProcess.slice(this.currentStage - 2, this.currentStage + 1);
                    }
                }
                else {
                    this.process = this.allProcess;
                }
                this.currentStage = this.currentStage + 1;
            },
            getcolor: function(data) {
                this.colorQ= data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            async nextStage() {
                if (this.process[2].sequence_number == this.lenProcess) {
                    return;
                } else {
                    let n = this.process[2].sequence_number;
                    n = n + 2;
                    await this.changeAddStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            async preStage() {
                if (this.process[0].sequence_number == 1) {
                    return;
                } else {
                    let n = this.process[0].sequence_number;
                    n = n - 2;
                    await this.changeMinusStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            changeAddStage(n) {
                if (n > this.lenProcess - 2) {
                    this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                } else {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            changeMinusStage(n) {
                if (n < 3) {
                    this.process = this.allProcess.slice(0, 3);
                } else {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            onPhoneCheck(event) {
            this.vue_tel = event;
            if (this.vue_tel.isValid == true) {
                this.isNumVaild = true;
            }
            },
            onPhoneInput() {
                if (this.isNumVaild == true) {
                    if (this.editCaseData.incoming_phone.value != this.vue_tel.number.input && this.editCaseData.incoming_phone.value != "") {
                        this.isNumVaild = false;
                    }
                } else {
                    if (this.editCaseData.incoming_phone.value == "") {
                        this.isNumVaild = true;
                    }
                }
            },
            getResponsedata(){
                this.$refs.CFRequired.emitData();
                if(this.$refs.CFRequired.responsedata === false)
                {
                    this.$refs.CFRequired.responsedata = null;
                    this.cfValid = false;
                    this.editBtn_loading = false;
                    return;
                }
                else if(this.$refs.CFRequired.responsedata === null)
                    this.customFieldData = [];
                else
                    this.customFieldData = this.$refs.CFRequired.responsedata;
            },
        },
        computed: {
            isNew: function(){
                if(this.editCaseData.case_org.value == "" || this.editCaseData.case_org.value == null){
                    return false;
                }
                if(typeof (this.editCaseData.case_org.value) === 'object'){
                    if(this.editCaseData.case_org.value.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            },
        },
        watch: {
            "errorDialog":function(){
                if(!this.errorDialog){
                    this.editBtn_loading = false;
                }
            },
            async search(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.isLoading && charNum>=2 && charNum==val.length ) {
                        this.isLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                        .then(response => { 
                            this.organizationList = response.data; 
                            let num = 0
                            this.organizationList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })
                        })
                        .finally(() => (this.isLoading = false));
                    }
                    else {
                        this.isLoading = false;
                    }
                }
            },
            "editCaseData.stage_type.value":function(){
                this.currentStage = 2;
                if(this.change_stage && this.editCaseData.stage_type.value){
                    this.passId = this.editCaseData.stage_type.value.id;
                }else{
                    this.passId = this.stageTypeId.id;
                }
            },
            "change_stage":function(){
                if(!this.change_stage){
                    this.passId = this.stageTypeId.id;
                }else if(this.editCaseData.stage_type.value){
                    this.passId = this.editCaseData.stage_type.value.id;
                }
            },
            "isNewContact":function(){
                    this.editCaseData.primary_contact.value = ''
            },
            contactSearch(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }
                else{
                    this.contactList = [];
                }
            },
            async search_parent_case(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                // search
                if(isEnZhNum && !!val && isString) {
                    this.parent_case_list_loading = true;
                    this.comboInput_parent_case = val

                    await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
                    .then(response => {
                        this.caseParentCaseList = response.data.list
                    })
                    .finally(() => {
                        this.parent_case_list_loading = false;
                    })
                }
                else{
                    this.comboInput_parent_case = null
                }
            }
        },
    });
</script>

<style scoped>
.process-bar {
    width: calc(100% );
    margin-left: 0px;
}
.no-padding {
    padding: 0px;
}

</style>